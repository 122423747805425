/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@tailwind base;
@tailwind components;
@tailwind utilities;


.dense {
  @include mat.all-component-densities(-3);
}

:root {
  --sp-green: #8fb340;
  --sp-green-transparent-10: #8fb340e6;
  --sp-green-transparent-50: #8fb34080;
  --sp-green-transparent-75: #8fb34040;
  --sp-green-transparent-80: #8fb34020;
  --sp-green-transparent-90: #8fb34010;
  --sp-green-transparent-75-solid: #909681;
  --sp-blue: #47C7E4;
  --sp-grey: #b6b6b7;
  --sp-red: #ef4444;
}

* {
  box-sizing: border-box;

  --mdc-theme-primary: var(--sp-green);
  --mdc-checkbox-selected-icon-color: var(--sp-green);
  --mdc-checkbox-selected-hover-icon-color: var(--sp-green);
  --mdc-checkbox-selected-pressed-icon-color: var(--sp-green);
  --mdc-checkbox-selected-focus-icon-color: var(--sp-green);

  --mdc-switch-selected-track-color: var(--sp-green);
  --mdc-switch-selected-focus-track-color: var(--sp-green);
  --mdc-switch-selected-hover-track-color: var(--sp-green);
  --mdc-switch-selected-pressed-track-color: var(--sp-green);
  --mdc-switch-selected-icon-color: rgba(255, 255, 255, 0.85);
  --mdc-switch-selected-handle-color: var(--sp-green);
  --mdc-switch-selected-focus-handle-color: var(--sp-green);
  --mdc-switch-selected-pressed-handle-color: var(--sp-green);
  --mdc-switch-selected-hover-handle-color: var(--sp-green);
  --mdc-switch-selected-pressed-state-layer-color: var(--sp-green);
  --mdc-switch-selected-focus-state-layer-color: var(--sp-green);


  --mdc-slider-handle-color: var(--sp-green);
  --mdc-slider-focus-handle-color: var(--sp-green);
  --mdc-slider-hover-handle-color: var(--sp-green);
  --mdc-slider-active-track-color: var(--sp-green);
  --mdc-slider-inactive-track-color: var(--sp-green);
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: var(--sp-green);
  --mat-mdc-slider-hover-ripple-color: var(--sp-green-transparent-80);
  --mat-mdc-slider-focus-ripple-color: var(--sp-green-transparent-75);
  --mat-mdc-slider-ripple-color: var(--sp-green);

  --mdc-radio-selected-icon-color: var(--sp-green);
  --mdc-radio-selected-hover-icon-color: var(--sp-green);
  --mat-mdc-radio-checked-ripple-color: var(--sp-green);
  --mdc-radio-selected-pressed-icon-color: var(--sp-green);
  --mdc-checkbox-selected-focus-state-layer-color: var(--sp-green-transparent-10);
  --mdc-switch-selected-hover-state-layer-color: var(--sp-green-transparent-10);

  --mdc-outlined-text-field-focus-outline-color: var(--sp-green);
  --mat-option-selected-state-label-text-color: var(--sp-green);
  --mdc-filled-text-field-focus-active-indicator-color: var(--sp-green);
  --mdc-filled-text-field-caret-color: var(--sp-green);
  --mat-mdc-button-persistent-ripple-color: var(--sp-green);
  --mat-mdc-button-ripple-color: var(--sp-green-transparent-80);
  --mdc-outlined-button-label-text-color: var(--sp-green);
  --mdc-linear-progress-active-indicator-color: var(--sp-green);
  --mdc-linear-progress-track-color: var(--sp-green-transparent-80);

  --mdc-snackbar-container-color: var(--sp-blue);
  --mat-snack-bar-button-color: #fff;
  --mdc-snackbar-supporting-text-color: #fff;
  --mdc-circular-progress-active-indicator-color: var(--sp-blue);

  --mat-tab-header-active-label-text-color: var(--sp-blue);
  --mdc-tab-indicator-active-indicator-color: var(--sp-blue);

  --mat-tab-header-active-focus-label-text-color: var(--sp-blue);
  --mat-tab-header-active-hover-label-text-color: var(--sp-blue);
  --mat-tab-header-active-focus-indicator-color: var(--sp-blue);
  --mat-tab-header-active-hover-indicator-color: var(--sp-blue);
  --mat-tab-header-active-ripple-color: var(--sp-blue);
  --mat-tab-header-inactive-ripple-color: var(--sp-blue);
}

.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-elevated-container-color: var(--sp-blue);
  --mdc-chip-elevated-disabled-container-color: var(--sp-blue);
}

.mdc-button.mat-mdc-button-base[color=primary] {
  --mdc-text-button-label-text-color: var(--sp-green) !important;
  --mdc-filled-button-container-color: var(--sp-green) !important;
  --mdc-filled-button-label-text-color: white;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--sp-green) !important;
}

html {
  overflow-x: hidden;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


.sp-bg-sp-red {
  background: var(--sp-red) !important;
  background-color: var(--sp-red) !important;
}

.sp-bg-sp-green {
  background: var(--sp-green) !important;
  background-color: var(--sp-green) !important;
}

.sp-text-sp-green {
  color: var(--sp-green);
}

.sp-bg-sp-blue {
  background: var(--sp-blue) !important;
  background-color: var(--sp-blue) !important;
}

.sp-text-sp-blue {
  color: var(--sp-blue);
}

.sp-bg-sp-red {
  background: var(--sp-red);
}

.sp-text-sp-red {
  color: var(--sp-red);
}

.sp-bg-sp-grey {
  background: var(--sp-grey);
}

.sp-text-sp-grey {
  color: var(--sp-grey);
}

.sp-hover-green {
  &:hover {
    color: var(--sp-green);
  }
}

input[type=text] {
  letter-spacing: 0.5px;
}

input[type=password] {
  letter-spacing: 2px;
}

input[type=password]::placeholder {
  letter-spacing: 0.5px;
}

.snackbar {
  margin-bottom: 120px !important;
}

p, td, th, div.sp-text-2xl, div.sp-text-xl, div.sp-text-3xl {
  letter-spacing: 1px !important;
}

.mat-mdc-menu-panel.settings-menu {
  i {
    width: 22px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.mat-mdc-menu-panel.admin-menu {
  i {
    width: 22px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .mat-mdc-menu-content {
    padding-top: 0 !important;
    padding-bottom: 0.25rem !important;
  }
}
